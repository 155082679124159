import React from 'react';
import { Divider, Footer, Recommendation } from '../components';
import { useRecommendations } from '../hooks';

export default () => {
   const recommendations = useRecommendations();

   return (
      <main className="font-mono">
         {/* Recommendations */}
         <section className="min-h-screen bg-gray-200">
            <div className="container py-20 mx-auto">
               <div className="text-2xl text-center text-gray-700 md:text-4xl">
                  All Recommendations
               </div>
               <div className="relative flex flex-col items-center mt-12">
                  {recommendations.map(recommendation => (
                     <Recommendation
                        key={recommendation.id}
                        {...recommendation}
                        full
                     />
                  ))}
               </div>
            </div>
         </section>

         <Divider className="text-gray-200 bg-gray-100" />

         {/* Footer */}
         <Footer className="bg-gray-100" />
      </main>
   );
};
